import React, { Component } from 'react'

import { Content } from '../Styling'
import api from '../Api'

export default class App extends Component {
  constructor(props) {
    super(props)

    this.text = {
      admin: 'Administrator',
      request: 'Zugang beantragt',
      declined: 'Zugang abgelehnt',
      user: 'Benutzer',
    }

    this.state = { ...props.appstate, users: [] }
  }

  componentDidMount() {
    api({ action: 'user-list' }, ({ users }) => {
      this.setState({ users })
    })
  }

  handleClick = (ev) => {
    ev.preventDefault()
    const { currentTarget } = ev
    const id = parseInt(currentTarget.getAttribute('data-id'), 10)
    const { users } = this.state
    const user = users.find((user) => user.id === id)
    const action = currentTarget.getAttribute('data-action')
    let access = undefined
    let exe = false

    switch (action) {
      case 'user-accept':
        user.role = 'user'
        exe = true
        break

      case 'user-decline':
        user.role = 'declined'
        exe = true
        break

      case 'user-admin':
        user.role = user.role === 'admin' ? 'user' : 'admin'
        exe = true
        break

      case 'user-delete':
        exe = window.confirm('Sind Sie sicher?')
        break

      case 'user-access':
        const userAccess = user.access ? JSON.parse(user.access) : []
        if (userAccess.includes(currentTarget.getAttribute('data-access'))) {
          access = userAccess.filter(
            (a) => a !== currentTarget.getAttribute('data-access')
          )
        } else {
          access = [...userAccess, currentTarget.getAttribute('data-access')]
        }
        exe = true
        access = JSON.stringify(access)
        break

      default:
        exe = true
    }

    if (exe) {
      api(
        { action, user: users.find((user) => user.id === id), access },
        ({ error }) => {
          if (!error) {
            api({ action: 'user-list' }, ({ users }) =>
              this.setState({ users })
            )
          }
        }
      )
    }
  }

  handleChange = ({ currentTarget }) => {
    const id = parseInt(currentTarget.getAttribute('data-id'), 10)
    const { users } = this.state
    const field = currentTarget.name
    users.find((user) => user.id === id)[field] = currentTarget.value
    this.setState({ users })
  }

  render() {
    return !this.state.users || this.state.users.length === 0 ? null : (
      <Content>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Rolle</th>
              <th scope="col">Zugang</th>
              {this.state.feature['access'] && (
                <th scope="col">Berechtigung</th>
              )}
            </tr>
          </thead>
          <tbody>
            {this.state.users.map((user) => {
              const userAccess = user.access ? JSON.parse(user.access) : []
              return (
                <tr>
                  <td>
                    {user.lastname}, {user.firstname}
                  </td>
                  <td>{this.text[user.role]}</td>
                  <td>
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                      style={{ width: '220px' }}
                    >
                      {!['admin', 'user'].includes(user.role) && (
                        <button
                          className="btn btn-success btn-sm"
                          data-id={user.id}
                          data-action="user-accept"
                          onClick={this.handleClick}
                        >
                          gewähren
                        </button>
                      )}

                      {!['admin', 'declined'].includes(user.role) && (
                        <button
                          className="btn btn-primary btn-sm"
                          data-id={user.id}
                          data-action="user-decline"
                          onClick={this.handleClick}
                        >
                          ablehnen
                        </button>
                      )}

                      {!['admin', 'request'].includes(user.role) && (
                        <button
                          className="btn btn-danger btn-sm"
                          data-id={user.id}
                          data-action="user-delete"
                          onClick={this.handleClick}
                        >
                          löschen
                        </button>
                      )}

                      {user.id !== this.state.user.id &&
                        ['admin', 'user'].includes(user.role) && (
                          <button
                            className={`btn btn-${
                              user.role === 'admin' ? 'secondary' : 'success'
                            } btn-sm`}
                            data-id={user.id}
                            data-action="user-admin"
                            onClick={this.handleClick}
                          >
                            {user.role === 'admin'
                              ? 'Admin Zugang entfernen'
                              : 'Admin'}
                          </button>
                        )}
                    </div>
                  </td>
                  {this.state.feature['access'] && (
                    <td>
                      {['user', 'admin'].includes(user.role) && (
                        <>
                          <button
                            className={`btn btn-${
                              userAccess.includes('plans')
                                ? 'success'
                                : 'secondary'
                            } btn-sm mr-2`}
                            data-id={user.id}
                            data-action="user-access"
                            data-access="plans"
                            onClick={this.handleClick}
                          >
                            Baupläne
                          </button>
                          <button
                            className={`btn btn-${
                              userAccess.includes('construction')
                                ? 'success'
                                : 'secondary'
                            } btn-sm mr-2`}
                            data-id={user.id}
                            data-action="user-access"
                            data-access="construction"
                            onClick={this.handleClick}
                          >
                            Baupläne V2
                          </button>
                          <button
                            className={`btn btn-${
                              userAccess.includes('redirects')
                                ? 'success'
                                : 'secondary'
                            } btn-sm mr-2`}
                            data-id={user.id}
                            data-action="user-access"
                            data-access="redirects"
                            onClick={this.handleClick}
                          >
                            Weiterleitungen
                          </button>
                        </>
                      )}
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      </Content>
    )
  }
}
