import React, { Component, forwardRef } from 'react'
import qrcode from 'qrcode-generator-es6'
import { Content, Head, QRcode } from '../Styling'
import getSrcFromSvg from '../lib/getSrcFromSvg'
import obstruction from './logo_svg'
import {
  FilterIcon,
  PlusIcon,
  ClockIcon,
  CircleSlashIcon,
  CheckCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@primer/octicons-react'
import { BsQrCode } from 'react-icons/bs'
import api from '../Api'

import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

const alpha = Array.from(Array(26)).map((e, i) => i + 65)
const alphabet = alpha.map((x) => String.fromCharCode(x))

const getFormattedTimes = (qr) => {
  const targetTime = new Date(qr.creation_date.replace(/-/g, '/'))
  const offsetTime = new Date(
    targetTime.getTime() - 60 * 1000
  ).toLocaleDateString('de-DE', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  const offsetExTime =
    new Date(targetTime.getTime() - 60 * 1000).toLocaleDateString('de-DE', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }) + ' Uhr'

  const created = {
    short: `${qr.user.lastname}, ${qr.user.firstname} - ${offsetTime}`,
    extended: `Erstellt von ${qr.user.lastname}, ${qr.user.firstname} am ${offsetExTime}`,
  }

  const changed = {
    short: '',
    extended: '',
  }

  if (qr.change_date) {
    const targettTime = new Date(qr.change_date.replace(/-/g, '/'))
    const changeTime = new Date(
      targettTime.getTime() - 60 * 1000
    ).toLocaleDateString('de-DE', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    const changeExTime =
      new Date(targettTime.getTime() - 60 * 1000).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }) + ' Uhr'

    changed.short = `${qr.modifier.lastname}, ${qr.modifier.firstname} - ${changeTime}`
    changed.extended = `Geändert von ${qr.modifier.lastname}, ${qr.modifier.firstname} am ${changeExTime}`
  }

  return { created, changed }
}

export default class App extends Component {
  constructor(props) {
    super()
    this.state = {
      realDate: new Date(),
      user_id: props?.appstate?.user?.id || 1,
      query: '',
      valid: false,
      list: undefined,
      showId: undefined,
      showSvg: undefined,
      showCode: undefined,
      show: undefined,
      date: undefined,
      previous: undefined,
      limit: window.localStorage.getItem('limit') ?? '500',
      filterStatus: window.localStorage.getItem('filterStatus') ?? "'1', '2'",
      filterDateFrom: window.localStorage.getItem('filterDateFrom')
        ? new Date(window.localStorage.getItem('filterDateFrom'))
        : undefined,
      filterDateTo: window.localStorage.getItem('filterDateTo')
        ? new Date(window.localStorage.getItem('filterDateTo'))
        : undefined,
      sort: window.localStorage.getItem('sort') ?? 'code',
      dir: window.localStorage.getItem('dir') ?? 'asc',
    }
  }

  componentDidMount() {
    this.loadList()
    // TinyDatePicker('#tiny-date-picker-v2', datepickerConfig)
  }

  handleSubmit = (ev) => {
    ev.preventDefault()

    const formData = new FormData(ev.currentTarget)
    let version = formData.get('version')?.toString()
    if (!isNaN(version)) {
      version = version.padStart(3, '0')
    }
    if (this.state?.previous?.newVersion) {
      version = this.state?.previous?.newVersion
    }
    const cost =
      this.state?.previous?.cost ??
      formData.get('cost')?.toString().toUpperCase()
    const date = formData.get('date')?.toString()
    const plan = this.state?.previous?.plan ?? formData.get('plan')?.toString()
    const status = formData.get('status')?.toString()
    const note = formData.get('note')?.toString()

    // console.log({ cost, date, plan, status, note, version })

    if (
      cost.length === 4 &&
      date.length === 6 &&
      version.length > 0 &&
      plan.length > 0 &&
      status.length > 0
    ) {
      const server = window.location.origin.includes('localhost')
        ? 'http://192.168.178.145:8888'
        : window.location.origin
      const code = `${cost}-${date}-${version}-${plan}`
      const payload = `${server}/plan/?code=${encodeURIComponent(code)}`
      const qr = new qrcode(0, 'H')
      qr.addData(payload)
      qr.make()
      const svg = qr.createSvgTag({
        margin: 0,
        obstruction,
      })
      this.setState({
        code,
        payload,
        svg,
        note,
        status,
        version,
        cost,
        date,
        plan,
        valid: true,
        previous: this.state?.previous
          ? {
              newVersion: this.state?.previous?.newVersion,
              code: this.state?.previous?.code,
              payload,
              svg,
              note,
              status,
              version,
              cost,
              date,
              plan,
              valid: true,
            }
          : undefined,
      })
    }
  }

  handleSave = () => {
    if (this.state?.previous) {
      api(
        Object.assign(
          { action: 'plan-new-version', invalidate: this.state.previous.code },
          {
            user_id: this.state.user_id,
            code: this.state.code,
            svg: this.state.svg,
            status: this.state.status,
            payload: this.state.payload,
            note: this.state.note,
            cost: this.state.cost,
            date: this.state.date,
            version: this.state.version,
            plan: this.state.plan,
          }
        ),
        (response) => {
          if (!response.error) {
            if (document.querySelector('#close-modal')) {
              document.querySelector('#close-modal').click()
            }
            this.loadList()
            this.setState({
              code: undefined,
              svg: undefined,
              status: undefined,
              payload: undefined,
              note: undefined,
              cost: undefined,
              date: undefined,
              version: undefined,
              plan: undefined,
              previous: undefined,
            })
          }
        }
      )
    } else {
      api(
        Object.assign(
          { action: 'plan-create' },
          {
            user_id: this.state.user_id,
            code: this.state.code,
            svg: this.state.svg,
            status: this.state.status,
            payload: this.state.payload,
            note: this.state.note,
            cost: this.state.cost,
            date: this.state.date,
            version: this.state.version,
            plan: this.state.plan,
          }
        ),
        (response) => {
          if (!response.error) {
            if (document.querySelector('#close-modal')) {
              document.querySelector('#close-modal').click()
            }
            this.setState({
              code: undefined,
              svg: undefined,
              status: undefined,
              payload: undefined,
              note: undefined,
              cost: undefined,
              date: undefined,
              version: undefined,
              plan: undefined,
              previous: undefined,
              valid: false,
            })
            this.loadList()
          } else {
            if (response.error.includes('Duplicate entry')) {
              alert(
                'Fehler:\nDieser Code existiert bereits.\n\nHinweis:\nSuchen Sie den Code über die Suche und erstellen Sie eine neue Version über das (+) Symbol auf der selben Zeile.'
              )
            } else {
              alert(response.error)
            }
          }
        }
      )
    }
  }

  setStatus = (id, status) => {
    // console.log('set-status', id, status)
    api(
      { action: 'plan-set-status', id, status, change_id: this.state.user_id },
      (response) => {
        if (!response.error) {
          this.loadList()
        }
      }
    )
  }

  deleteCode = (id) => {
    // console.log('delecteCode', id)
    api({ action: 'plan-delete', id }, (response) => {
      if (!response.error) {
        this.loadList()
      }
    })
  }

  resetModal = () => {
    // console.log('resetModal')
    this.setState({ previous: undefined })
  }

  loadList = (
    filterStatus = this.state.filterStatus,
    limit = this.state.limit
  ) => {
    api({ action: 'plan-list', filterStatus, limit }, (response) => {
      if (!response.error) {
        this.setState({ list: response.list })
      }
    })
  }

  loadSvg = (id) => {
    const show = this.state.list.find((qr) => qr.id === id)
    this.setState({ showId: id, showSvg: undefined, show })
    api({ action: 'plan-load-svg', id }, (response) => {
      if (!response.error) {
        this.setState({ showSvg: response.svg, showCode: response.code })
      }
    })
  }

  getStatusColor = (status) => {
    switch (status) {
      case '2':
        return '#FEBC2E' // apple yellow
      case '1':
        return '#28C840' // apple green
      default:
        return '#ED1C25' // strabag red
    }
  }

  handleCopy = async () => {
    const img = document.createElement('img')
    const canvas = new OffscreenCanvas(1000, 1000)
    const ctx = canvas.getContext('2d')
    img.style.cssText =
      'position: absolute; top: -1px; left: -1px; width: 1px; height: 1px;'
    img.setAttribute('src', getSrcFromSvg(this.state.showSvg))
    document.body.appendChild(img)
    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(img, 0, 0)
    const clipboardData = {
      'image/png': await canvas.convertToBlob(),
    }

    try {
      // eslint-disable-next-line no-undef
      await navigator.clipboard.write([new ClipboardItem(clipboardData)])
    } catch (err) {
      alert('Diese Funktion erlaubt ihr verwendeter Browser leider nicht.')
    } finally {
      img.remove()
    }
  }

  handleDownload = async () => {
    const img = document.createElement('a')
    img.style.cssText = 'position: absolute; top: -2500px; left: -2500px'
    img.setAttribute('href', getSrcFromSvg(this.state.showSvg))
    img.setAttribute('download', `${this.state.showCode}.svg`)
    document.body.appendChild(img)
    img.click()
    img.remove()
  }

  render() {
    // console.log(this.state)
    // console.log(alphabet)

    const filtered = (this.state?.list ?? [])
      .filter(
        (qr) =>
          qr.code.toLowerCase().includes(this.state.query.toLowerCase()) ||
          `${qr.user.lastname}${qr.user.firstname}`
            .toLowerCase()
            .includes(this.state.query.toLowerCase())
      )
      .filter((qr) => {
        if (!this.state.filterDateFrom) {
          return true
        }
        const startOfDay = new Date(this.state.filterDateFrom)
        startOfDay.setHours(0, 0, 0, 0)
        return new Date(qr.creation_date) >= startOfDay
      })
      .filter((qr) => {
        if (!this.state.filterDateTo) {
          return true
        }
        const endOfDay = new Date(this.state.filterDateTo)
        endOfDay.setHours(23, 59, 59, 999)
        return endOfDay >= new Date(qr.creation_date)
      })
      .sort((a, b) =>
        this.state.dir === 'desc'
          ? (b?.[this.state.sort] ?? '').localeCompare(
              a?.[this.state.sort] ?? ''
            )
          : (a?.[this.state.sort] ?? '').localeCompare(
              b?.[this.state.sort] ?? ''
            )
      )

    const DateInput = forwardRef(({ onClick }, ref) => (
      <input
        onClick={onClick}
        defaultValue={this.state?.date ?? this.state?.previous?.date ?? ''}
        id="tiny-date-picker-v2"
        type="text"
        name="date"
        className="form-control"
        placeholder="JJMMDD"
        maxLength="6"
        required
        // pattern="^[2-3][0-9][0-1][1-2][0-2][0-9]$"
        style={{ width: '385px' }}
      />
    ))

    const FilterDateFrom = forwardRef(({ value, onClick }, ref) => {
      if (value) {
        const [day, month, year] = value.split('.')
        window.localStorage.setItem('filterDateFrom', `${year}/${month}/${day}`)
      }
      return (
        <input
          onClick={onClick}
          ref={ref}
          className="form-control"
          type="text"
          defaultValue={value}
          style={{ width: '182px' }}
        />
      )
    })

    const FilterDateTo = forwardRef(({ value, onClick }, ref) => {
      if (value) {
        const [day, month, year] = value.split('.')
        window.localStorage.setItem('filterDateTo', `${year}/${month}/${day}`)
      }
      return (
        <input
          onClick={onClick}
          ref={ref}
          className="form-control"
          type="text"
          defaultValue={value}
          style={{ width: '182px' }}
        />
      )
    })

    return (
      <Content>
        <Head>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <h5
              style={{
                display: 'inline',
                width: '200px',
                paddingTop: '6px',
              }}
            >
              Baupläne V2
            </h5>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div className=" mr-2">
                <div className="input-group mr-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Suche</span>
                  </div>
                  <input
                    title="Volltextsuche"
                    value={this.state.query}
                    style={{ width: '200px' }}
                    onChange={(ev) =>
                      this.setState({ query: ev.currentTarget.value })
                    }
                    type="text"
                    name="plan"
                    className="form-control"
                    maxLength="80"
                    required
                  />
                </div>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary mr-2"
                  data-toggle="modal"
                  data-target="#filterModal"
                  title="Codes Filtern"
                >
                  <FilterIcon size={16} />
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-info mr-3"
                  data-toggle="modal"
                  data-target="#createConstructionModal"
                  title="Neuen Code erstellen"
                  onClick={() => this.resetModal()}
                >
                  <PlusIcon size={16} />
                </button>
              </div>
            </div>
          </div>
        </Head>

        <div className="card">
          <ul className="list-group list-group-flush">
            {!this.state.list ? (
              <li className="list-group-item">Lade </li>
            ) : (
              <>
                {(this.state.list.length === 0 || filtered.length === 0) && (
                  <li className="list-group-item">
                    Es wurden keine Codes mit ihren Such-, bzw. Filterkriterien
                    gefunden.
                  </li>
                )}
                {this.state.list.length > 0 &&
                  filtered.length > 0 &&
                  filtered.map((qr) => {
                    const { created, changed } = getFormattedTimes(qr)

                    return (
                      <li
                        key={qr.code}
                        className="list-group-item"
                        style={{
                          background: `linear-gradient(90deg, ${this.getStatusColor(
                            qr.status
                          )} 0%, #FFFFFF 16px, #FFFFFF 100%`,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: '8px',
                            }}
                          >
                            <button
                              type="button"
                              className="btn btn-sm btn-light ml-1"
                              data-toggle="modal"
                              data-target="#showSvgModal"
                              onClick={() => this.loadSvg(qr.id)}
                              title="Code anzeigen"
                            >
                              <BsQrCode
                                style={{
                                  color: 'black',
                                  width: '36px',
                                  height: '36px',
                                  margin: '0',
                                }}
                              />
                            </button>
                            <div
                              style={{
                                width: '100%',
                              }}
                            >
                              <div style={{ fontWeight: 'bold' }}>
                                {qr.code}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <div
                                  style={{
                                    width: '50%',
                                    opacity: 0.6,
                                  }}
                                  className="mr-2"
                                  title={created.extended}
                                >
                                  {created.short}
                                </div>
                                <div
                                  style={{
                                    width: '50%',
                                    opacity: 0.6,
                                  }}
                                  className="mr-2"
                                  title={changed.extended}
                                >
                                  {changed.short}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="btn-group" role="group">
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary"
                              style={{
                                background: this.getStatusColor('1'),
                                color: '#000000 !important',
                              }}
                              disabled={qr.status === '1'}
                              onClick={() => this.setStatus(qr.id, '1')}
                              title="Code gültig machen"
                            >
                              <CheckCircleIcon size={24} />
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary"
                              style={{
                                background: this.getStatusColor('2'),
                              }}
                              disabled={qr.status === '2'}
                              onClick={() => this.setStatus(qr.id, '2')}
                              title="Code als Vorabversion markieren"
                            >
                              <ClockIcon size={24} />
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary"
                              style={{
                                background: this.getStatusColor('0'),
                              }}
                              disabled={qr.status === '0'}
                              onClick={() => this.setStatus(qr.id, '0')}
                              title="Code ungültig machen"
                            >
                              <CircleSlashIcon size={24} />
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary"
                              disabled={qr.status !== '0'}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    'Sind Sie wirklich sicher, dass dieser Code endgültig gelöscht werden soll?'
                                  )
                                ) {
                                  this.deleteCode(qr.id)
                                }
                              }}
                              title="Code endgültig löschen"
                            >
                              <TrashIcon size={24} />
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-info"
                              data-toggle="modal"
                              data-target="#createConstructionModal"
                              title="Neue Version des Codes erstellen"
                              onClick={() => {
                                this.setState(
                                  { previous: undefined, valid: false },
                                  () => {
                                    const previous = qr
                                    const year = (
                                      parseInt(previous.date.substr(0, 2), 10) +
                                      2000
                                    ).toString()
                                    const month = parseInt(
                                      previous.date.substr(2, 2),
                                      10
                                    )
                                      .toString()
                                      .padStart(2, '0')
                                    const day = parseInt(
                                      previous.date.substr(4, 2),
                                      10
                                    )
                                      .toString()
                                      .padStart(2, '0')
                                    previous.realDate = new Date(
                                      `${year}/${month}/${day}`
                                    )
                                    previous.newVersion =
                                      alphabet.indexOf(qr.version) === -1
                                        ? (parseInt(qr.version, 10) + 1)
                                            .toString()
                                            .padStart(3, '0')
                                        : alphabet[
                                            alphabet.indexOf(qr.version) + 1
                                          ]

                                    this.setState({ previous })
                                  }
                                )
                              }}
                            >
                              <PlusCircleIcon size={24} />
                            </button>
                          </div>
                        </div>
                      </li>
                    )
                  })}
              </>
            )}
          </ul>
        </div>

        <div
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          id="showSvgModal"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ paddingTop: '100px', maxWidth: '500px', width: '530x' }}
          >
            <div className="modal-content">
              <div
                className="modal-header"
                style={{
                  background: this.getStatusColor(this.state.show?.status),
                }}
              >
                <h5 className="modal-title">{this.state.show?.code}</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ maxWidth: '250px', margin: 'auto' }}>
                  {this.state.showSvg && (
                    <QRcode
                      size={250}
                      src={getSrcFromSvg(this.state.showSvg)}
                      alt={this.state.payload}
                      title={this.state.code}
                    />
                  )}
                </div>

                <div className="input-group mt-3">
                  <textarea
                    defaultValue={this.state?.show?.note ?? ''}
                    name="note"
                    className="form-control"
                    aria-label="With textarea"
                    readOnly
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                  style={{ width: '100%' }}
                >
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.handleDownload}
                  >
                    QR Code download SVG
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleCopy}
                  >
                    QR Code kopieren
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Schließen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="filterModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="filterModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ paddingTop: '100px', maxWidth: '568px', width: '568px' }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="filterModalLabel">
                  Filter
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="input-group mb-3">
                  <div
                    className="input-group-prepend"
                    style={{ width: '120px' }}
                  >
                    <label
                      className="input-group-text"
                      style={{ width: '120px' }}
                    >
                      Zeige
                    </label>
                  </div>
                  <select
                    value={this.state?.filterStatus}
                    className="custom-select"
                    name="version"
                    onChange={(ev) => {
                      const filterStatus = ev.currentTarget.value
                      window.localStorage.setItem('filterStatus', filterStatus)
                      this.setState({ filterStatus }, () =>
                        this.loadList(filterStatus ?? "'1', '2'")
                      )
                    }}
                  >
                    <option value="'0', '1', '2'">alle Codes</option>
                    <option value="'1', '2'">nur gültige Codes</option>
                    <option value="'2'">nur Vorabversionen</option>
                    <option value="'1'">nur freigegebene Codes</option>
                    <option value="'0'">nur ungültige Codes</option>
                  </select>
                </div>
                <div className="input-group mb-3">
                  <div
                    className="input-group-prepend"
                    style={{ width: '120px' }}
                  >
                    <label
                      className="input-group-text"
                      style={{ width: '120px' }}
                    >
                      Sortiere
                    </label>
                  </div>
                  <select
                    value={this.state.sort}
                    className="custom-select"
                    name="sort"
                    onChange={(ev) => {
                      const sort = ev.currentTarget.value
                      window.localStorage.setItem('sort', sort)
                      this.setState({ sort })
                    }}
                  >
                    <option value="code">nach Code</option>
                    <option value="creation_date">nach Erstellungsdatum</option>
                    <option value="change_date">nach Änderungsdatum</option>
                    <option value="status">nach Status</option>
                  </select>
                  <select
                    value={this.state.dir}
                    className="custom-select"
                    name="dir"
                    onChange={(ev) => {
                      const dir = ev.currentTarget.value
                      window.localStorage.setItem('dir', dir)
                      this.setState({ dir })
                    }}
                  >
                    <option value="asc">aufsteigend</option>
                    <option value="desc">absteigend</option>
                  </select>
                </div>
                <div className="input-group mb-3">
                  <div
                    className="input-group-prepend"
                    style={{ width: '120px' }}
                  >
                    <label
                      className="input-group-text"
                      style={{ width: '120px' }}
                    >
                      Datum von
                    </label>
                  </div>
                  <DatePicker
                    selected={this.state?.filterDateFrom}
                    onChange={(filterDateFrom) => {
                      this.setState({ filterDateFrom })
                    }}
                    customInput={<FilterDateFrom />}
                    dateFormat="d.MM.yyyy"
                  />
                  <label className="input-group-text">bis</label>
                  <DatePicker
                    selected={this.state?.filterDateTo}
                    onChange={(filterDateTo) => {
                      this.setState({ filterDateTo })
                    }}
                    customInput={<FilterDateTo />}
                    dateFormat="d.MM.yyyy"
                  />
                </div>
                <div className="input-group mb-3">
                  <div
                    className="input-group-prepend"
                    style={{ width: '120px' }}
                  >
                    <label
                      className="input-group-text"
                      style={{ width: '120px' }}
                    >
                      Anzahl (max)
                    </label>
                  </div>
                  <select
                    value={this.state?.limit}
                    className="custom-select"
                    name="limit"
                    onChange={(ev) => {
                      const limit = ev.currentTarget.value
                      window.localStorage.setItem('limit', limit)
                      this.setState({ limit }, () =>
                        this.loadList(undefined, limit)
                      )
                    }}
                  >
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="1500">1500</option>
                    <option value="9999999">ohne Limit</option>
                  </select>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    window.localStorage.setItem('limit', '500')
                    window.localStorage.setItem('filterStatus', "'1', '2'")
                    window.localStorage.removeItem('filterDateFrom')
                    window.localStorage.removeItem('filterDateTo')
                    window.localStorage.setItem('sort', 'code')
                    window.localStorage.setItem('dir', 'asc')
                    this.setState(
                      {
                        limit: window.localStorage.getItem('limit'),
                        filterStatus:
                          window.localStorage.getItem('filterStatus'),
                        filterDateFrom: undefined,
                        filterDateTo: undefined,
                        sort: window.localStorage.getItem('sort'),
                        dir: window.localStorage.getItem('dir'),
                      },
                      () => this.loadList()
                    )
                  }}
                >
                  Filter zurücksetzen
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Schließen
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="createConstructionModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="createConstructionModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ paddingTop: '100px', maxWidth: '568px', width: '568px' }}
          >
            {this.state.valid ? (
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="createConstructionModalLabel">
                    Angaben überprüfen
                  </h5>
                  <button
                    type="button"
                    id="close-modal"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="input-group mb-3">
                    <div
                      className="input-group-prepend"
                      style={{ width: '150px' }}
                    >
                      <span
                        style={{ width: '150px' }}
                        className="input-group-text"
                      >
                        {this.state.status === '2' && 'Vorabversion'}
                        {this.state.status === '1' && 'Freigegeben'}
                        {this.state.status === '0' && 'Ungültig'}
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      style={{
                        background: this.getStatusColor(this.state.status),
                        padding: '8px',
                      }}
                      readOnly
                      value={this.state.code}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <div
                      className="input-group-prepend"
                      style={{ width: '150px' }}
                    >
                      <span
                        className="input-group-text"
                        style={{ width: '150px' }}
                      >
                        Notiz
                      </span>
                    </div>
                    <textarea
                      readOnly
                      defaultValue={this.state?.note ?? ''}
                      className="form-control"
                    ></textarea>
                  </div>
                  <div className="input-group mb-3">
                    <div
                      className="input-group-prepend"
                      style={{ width: '150px' }}
                    >
                      <span
                        style={{ width: '150px' }}
                        className="input-group-text"
                      >
                        QR-Code
                      </span>
                    </div>
                    <div style={{ maxWidth: '250px' }}>
                      <QRcode
                        size={250}
                        src={getSrcFromSvg(this.state.svg)}
                        alt={this.state.payload}
                        title={this.state.code}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() =>
                      this.setState((current) => ({
                        valid: false,
                        note: current?.previous?.note ?? current.note,
                        status: current?.previous?.status ?? current.status,
                      }))
                    }
                  >
                    zurück
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => this.handleSave()}
                  >
                    speichern
                  </button>
                </div>
              </div>
            ) : (
              <form id="creation-form" onSubmit={this.handleSubmit}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title"
                      id="createConstructionModalLabel"
                    >
                      {this.state?.previous
                        ? 'Neuen Version erstellen'
                        : 'Neuen QR-Code erstellen'}
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="input-group mb-3">
                      <div
                        className="input-group-prepend"
                        style={{ width: '150px' }}
                      >
                        <span
                          style={{ width: '150px' }}
                          className="input-group-text"
                        >
                          Kostenstelle
                        </span>
                      </div>
                      <input
                        defaultValue={
                          this.state?.previous?.cost ?? this.state?.cost ?? ''
                        }
                        type="text"
                        name="cost"
                        className="form-control"
                        placeholder="XXXX"
                        maxLength="4"
                        required
                        pattern="[A-Za-z]{4}"
                        disabled={!!this.state?.previous?.cost}
                      />
                    </div>
                    <div className="input-group mb-3">
                      <div
                        className="input-group-prepend"
                        style={{ width: '150px' }}
                      >
                        <span
                          style={{ width: '150px' }}
                          className="input-group-text"
                        >
                          Datum
                        </span>
                      </div>
                      <DatePicker
                        selected={
                          this.state?.previous?.realDate ?? this.state.realDate
                        }
                        onChange={(realDate) => {
                          let day = realDate.getDate()
                          let month = realDate.getMonth()
                          month += 1
                          if (String(day).length === 1) {
                            day = '0' + day
                          }
                          if (String(month).length === 1) {
                            month = '0' + month
                          }
                          const date = `${
                            realDate.getFullYear() - 2000
                          }${month}${day}`

                          this.setState({ date })
                        }}
                        customInput={<DateInput />}
                      />
                    </div>

                    <div className="input-group mb-3">
                      <div
                        className="input-group-prepend"
                        style={{ width: '150px' }}
                      >
                        <label
                          className="input-group-text"
                          htmlFor="inputGroupSelect01"
                          style={{ width: '150px' }}
                        >
                          Änderungsindex
                        </label>
                      </div>
                      {this.state.previous ? (
                        <input
                          defaultValue={this.state.previous.newVersion}
                          type="text"
                          id="inputGroupSelect01"
                          name="version"
                          className="form-control"
                          maxLength="60"
                          required
                          disabled
                        />
                      ) : (
                        <select
                          defaultValue={this.state?.version ?? ''}
                          className="custom-select"
                          id="inputGroupSelect01"
                          name="version"
                        >
                          <option value="0">000</option>
                          <option value="A">A</option>
                        </select>
                      )}
                    </div>
                    <div className="input-group mb-3">
                      <div
                        className="input-group-prepend"
                        style={{ width: '150px' }}
                      >
                        <span
                          style={{ width: '150px' }}
                          className="input-group-text"
                        >
                          Plannummer
                        </span>
                      </div>
                      <input
                        defaultValue={
                          this.state?.previous?.plan ?? this.state?.plan ?? ''
                        }
                        type="text"
                        name="plan"
                        className="form-control"
                        maxLength="60"
                        required
                        disabled={!!this.state?.previous?.plan}
                      />
                    </div>
                    <div className="input-group mb-3">
                      <div
                        className="input-group-prepend"
                        style={{ width: '150px' }}
                      >
                        <label
                          className="input-group-text"
                          htmlFor="inputGroupSelect02"
                          style={{ width: '150px' }}
                        >
                          Status
                        </label>
                      </div>
                      <select
                        defaultValue={
                          this.state?.previous?.status ??
                          this.state?.status ??
                          '2'
                        }
                        className="custom-select"
                        id="inputGroupSelect02"
                        name="status"
                      >
                        <option value="2">Vorabversion (gelb)</option>
                        <option value="1">Freigegeben (grün)</option>
                        <option value="0" disabled>
                          Ungültig (rot)
                        </option>
                      </select>
                    </div>
                    <div className="input-group">
                      <div
                        className="input-group-prepend"
                        style={{ width: '150px' }}
                      >
                        <span
                          className="input-group-text"
                          style={{ width: '150px' }}
                        >
                          Notiz
                        </span>
                      </div>
                      <textarea
                        defaultValue={
                          this.state?.previous?.note ?? this.state?.note ?? ''
                        }
                        name="note"
                        className="form-control"
                        aria-label="With textarea"
                      ></textarea>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      abbrechen
                    </button>
                    <button type="submit" className="btn btn-primary">
                      weiter
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </Content>
    )
  }
}
